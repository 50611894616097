const elements = [...document.getElementsByClassName('sticky')]
  .slice(0, 1)
  .map(el => ({ el, isFixed: false, top: 0 }))

const setPositionRelative = el => {
  el.style.position = 'relative'
  el.style.width = '100%'
}

const setFixed = () => {
  elements.forEach(item => {
    const { el, isFixed } = item

    const parentRect = el.parentNode.getBoundingClientRect()
    const rect = el.getBoundingClientRect()

    const shouldBeFixed = parentRect.top < 0

    if (shouldBeFixed && !isFixed) {
      el.classList.add('sticky-fixed')
      item.isFixed = true
      const widthPx = el.offsetWidth + 'px'
      el.style.position = 'fixed'
      el.style.width = widthPx
    } else if (!shouldBeFixed) {
      el.classList.remove('sticky-fixed')
      item.isFixed = false
      setPositionRelative(el)
    }

    // responsive settings
    const tabletBreakpoint = el
      .getAttribute('data-breakpoints')
      ?.split(',')
      .pop()
      .slice(0, -2)

    if (tabletBreakpoint >= window.screen.width) {
      setPositionRelative(el)
    } else {
      highlightLink()
    }

    // scroll to bottom of parent
    if (parentRect.y + parentRect.height < rect.height) {
      el.parentNode.style.display = 'flex'
      el.parentNode.style.alignItems = 'flex-end'
      el.style.position = 'relative'
      item.isFixed = false
    } else el.parentNode.style.display = 'block'
  })
}

const measureElements = () => {
  elements.forEach(item => {
    item.top = item.el.parentNode.getBoundingClientRect().top + window.pageYOffset
  })
  setFixed()
}

const highlightLink = () => {
  elements.forEach(item => {
    const { el } = item
    const links = [...el.querySelectorAll('ul > li > a')]

    if (links.length) {
      const h2elements = [...el.parentElement.previousElementSibling.querySelectorAll('h2')]

      const applicableH2elements = h2elements.filter(h2element =>
        links.find(link => {
          const href = link.getAttribute('href')
          if (href && href.startsWith('#') && href.length === h2element.id.length + 1) {
            return href.indexOf(h2element.id, 1) !== -1
          }
          return false
        }),
      )

      if (applicableH2elements.length) {
        let minTopDistanceObj = {
          id: h2elements[0].id,
          y: Math.abs(h2elements[0].getBoundingClientRect().y),
          instance: h2elements[0],
        }

        applicableH2elements.forEach(h2element => {
          const y = Math.abs(h2element.getBoundingClientRect().y)

          if (y < minTopDistanceObj.y)
            minTopDistanceObj = { id: h2element.id, y, instance: h2element }
        })

        links.some(link => {
          const href = link.getAttribute('href')
          if (
            href &&
            href.startsWith('#') &&
            href.length === minTopDistanceObj.id.length + 1 &&
            href.indexOf(minTopDistanceObj.id, 1) !== -1
          ) {
            setTimeout(() => {
              link.focus()
            }, 0)
            return true
          }
          return false
        })
      }
    }
  })
}

if (elements.length > 0) {
  measureElements()
  window.addEventListener('scroll', setFixed, { passive: true })
  window.addEventListener('resize', measureElements)
}
