import 'lazysizes'
import '@u/sticky-fixed'

window.onload = function attachTracking() {
  const anchors = document.querySelectorAll('a[data-trackable=true]')
  for (let i = 0; i < anchors.length; i++) {
    const anchor = anchors[i]
    const action = anchor.getAttribute('data-action')
    const category = anchor.getAttribute('data-category')
    const label = anchor.getAttribute('data-label')

    anchor.onclick = function trackClick() {
      window.UswitchEventTracking(category, action, label)
    }
  }
}

console.log('client-critical-js')
